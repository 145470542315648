@import "./Variables.scss";

#not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.center-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-100w {
  margin: 0 !important;
  height: 1000px;
  min-height: 100%;
  border-radius: 0;
}

.custom-map-modal {
  .modal-dialog {
    max-width: 600px;
    height: 100%;
    padding: 0;
    margin: auto auto !important;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: 0;
  }
}

.info-modal {
  display: 'flex';
  justify-content: 'center';
}

.back-btn {
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
}

#main.container {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: auto !important;
  max-width: 1300px;
}

#main {
  .header {
    width: 100%;
  }

  .header-image {
    width: 100%;
    .restaurant-img {
      width: 100%;
      max-height: 330px;
      object-fit: cover;
    }
  }

  .restaurant-info {
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    .restaurant-name {
      color: black;
      font-size: 34px;
      font-weight: bold;
    }

    .restaurant-info-text {
      color: #383d41;
      font-size: 16px;
      white-space: pre-line;
      &.bullet:before {
        content: "\A";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--website-color);
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
      }
    }
    .restaurant-info-table {
      color: #383d41;
      font-size: 18px;
      cursor: "pointer";
      // -webkit-user-select: all;
      // -khtml-user-select: all;
      // -moz-user-select: all;
      // -o-user-select: all;
      // user-select: all;
    }
    .cart-title {
      font-weight: bold;
      font-size: 20px;
      color: black;
      padding: 12px 0;
    }
  }

  .category-container {
    display: flex;
    width: 100%;
    padding: 0 10px;
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
    white-space: nowrap;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    z-index: 1000;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    .options {
      padding: 20px 0;
      overflow: auto;
      cursor: pointer;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      .options-btn {
        text-decoration: none;
        color: black;
        background: var(--color-grey);
        padding: 0.6rem 0.8rem;
        border-radius: 30px;
        border: 0;
        font-family: var(--font-family);
        font-size: 1.2rem;
        letter-spacing: 0.6px;
        display: inline-flex;
        align-items: center;
        margin-right: 0.3rem;
        svg {
          max-width: 20px;
          margin-right: 0.4rem;
        }
        &.selected {
          background: var(--website-color);
          color: white;
        }
      }
    }
  }

  .product-container {
    background-color: white;
    padding-bottom: 80px;
    flex: 1;
    .product-box {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      height: 150px;
      margin: 0;
      border-radius: 6px;
      .product-box-quantity {
        position: absolute;
        top: 11px;
        right: 0;
        width: 30px;
        height: 30px;
        background-color: var(--website-color);
        text-align: center;
        line-height: 30px;
        font-size: 1.2em;
        color: white;
        border-bottom-left-radius: 6px;
      }
      .product-text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        .product-box-name {
          width: 100%;
          order: 1;
          display: flex;
          align-items: center;
          .icon {
            width: 18px;
            height: 18px;
          }
          .product-name {
            font-size: 18px;
            color: black;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 20px;
          }
        }
        .product-description {
          order: 2;
          font-size: 16px;
          color: #383d41;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 0;
            line-height: 20px;
          }
        }
        .product-price {
          order: 3;
          font-size: 18px;
        }
        .product-not-available {
          color: red;
        }
      }
      .img {
        height: 128px;
        width: 138px;
        object-fit: cover;
        border-radius: 6px;
      }
    }
    .product-margin {
      height: 1px;
      background-color: #dedede;
    }
  }

  .section-container > div:last-child > .product-margin {
    height: 0px;
  }

  .section {
    position: relative;
    display: flex;
    justify-content: center;
    height: 5vh;
    padding: 3% 0% 3% 0%;
    .section-title {
      font-weight: bold;
      font-size: 20px;
      color: black;
      background-color: white;
      z-index: 10;
      width: 100%;
      padding-top: 30px;
    }
    .section-description {
      white-space: pre-wrap;
      color: #222;
      text-align: center;
    }
    .product-margin:last-child {
      height: 0px;
    }
  }

  .product-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 10px 20px 15px;
  }

  .discount-row {
    color: black;
    padding: 0 0 15px 0;

    &:hover {
      color: black;
    }
  }

  .footer {
    background-color: white;
    z-index: 1000;
    &:hover {
      text-decoration: none;
    }
    .footer-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span:last-child {
        font-size: 0.9em;
      }
    }
    .footer-logo {
      display: flex;
    }
    .logo {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 130px;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.2);
      position: relative;
      margin: 10px 10px;
      border: 0;
      border-radius: 0.5em;
      cursor: pointer;

      &:active {
        top: 1px;
        left: 1px;
      }
      span {
        color: white;
      }
    }
    @extend .footer-block;
  }

  // sticky header
  /* The sticky class is added to the header with JS when it reaches its scroll position */
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .breakpoint__medium-up {
    padding-top: 64px;
  }

  .sticky + .breakpoint__small-down {
    padding-top: 64px;
  }

  .main-color {
    color: $mainColor;
  }

  @media (min-width: 500px) {
    .header-image {
      .restaurant-img {
        max-height: 450px;
      }
    }

    .modal-100w {
      margin: 0 !important;
      height: 1000px;
      min-height: 100%;
      border-radius: 0;
    }
    .product-container {
      flex: 2.6 0;
      background-color: white;
      padding-bottom: 80px;
      .product {
        display: grid;
        grid-template-columns: repeat(auto-fill, 190px);
        justify-content: space-around;
        grid-gap: 5px;
      }
      .product-box {
        display: flex;
        flex-direction: column;
        flex-basis: 49%;
        height: 280px;
        max-width: 220px;
        min-width: 170px;
        border: 1px solid #e1e1e1;
        align-items: center;
        padding: 0;
        margin: 10px 0;
        border-radius: 6px;
        cursor: pointer;
        &:hover {
          border: 1px solid var(--website-color);
        }
        &.selected {
          border: 2px solid var(--website-color);
          .img {
            height: 126px;
          }
        }
        .product-box-quantity {
          top: 0px;
        }
        .product-text-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 10px 10px 0px 10px;
          .product-allergen {
            position: absolute;
            bottom: 1px;
            left: 1px;
          }
          .product-box-name {
            width: 100%;
            order: 1;
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            .icon {
              width: 15px;
              height: 15px;
            }
            .product-name {
              font-size: 20px;
              color: black;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              line-height: 20px;
            }
          }
          .product-description {
            order: 2;
            font-size: 16px;
            color: #383d41;
            p {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              margin-bottom: 0;
              line-height: 20px;
            }
          }
          .product-price {
            order: 3;
            font-size: 18px;
          }
        }
        .img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 6px;
        }
      }
    }

    .section {
      position: relative;
      display: flex;
      align-items: center;
      height: 5vh;
      padding: 10% 0% 10% 0%;
      flex-direction: column;
      .section-title {
        font-weight: bold;
        font-size: 26px;
        color: black;
        background-color: white;
        z-index: 10;
        text-align: center;
      }
    }

    .product-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 70px 10px 20px 10px;
    }

    .cart {
      flex: 1 0;
      display: flex;
      flex-direction: column;
      height: 510px;
      box-shadow: rgba(0, 0, 0, 0.2) 1px 4px 10px;
      border-top: 0px;
      position: sticky;
      top: 63px;
      min-width: 320px;
      padding: 10px;
      margin-left: 20px;
      overflow: auto;
      .cart-title {
        font-weight: bold;
        font-size: 20px;
        color: black;
        padding: 12px 0;
      }
      .cart-content {
        flex-grow: 1;
        max-height: 320px;
        margin-bottom: 8px;
        overflow: auto;
      }
    }
  }

  @media (min-width: 500px) and (max-width: 900px) {
    .product-container {
      .product-box {
        flex-basis: 100%;
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 900px) {
  .custom-map-modal {
    .modal-dialog {
      max-width: 500px;
      height: 100%;
      padding: 0;
      margin: auto auto !important;
    }
    .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
      border: 0;
    }
  }
}
