.cart-product-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
  .text-number-product {
    font-size: 18px;
    font-weight: bold;
    margin-right: 6%;
    min-width: 50px;
    max-width: 50px;
    text-align: center;
  }
  .cart-text {
    font-size: 20px;
  }
  .cart-product-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .rubric-text {
    font-size: 20px;
    font-style: italic;
  }
  .cart-row {
    width: 80%;
    display: flex;
    align-items: center;
  }

  .cart-rubric-list {
    padding-left: 20%;
  }
}